import React from 'react'
import { graphql } from 'gatsby'

const RedirectPageTemplate = ({ data }) => {
  const { page } = data
  const {discountCode, utmCampaign, utmMedium, utmSource, pageTo, pageToUrl} = page
  const slugType = pageTo?.__typename === 'ContentfulProduct'
    ? '/products/' : pageTo?.__typename === 'ContentfulBlogArticle'
    ? '/blog/' : '/'
  const slug = pageTo?.slug ? slugType+pageTo.slug : pageToUrl
  const path = !pageToUrl && discountCode ? `/discount/${discountCode}/?redirect=${slug}` : `${slug}`
  const source = utmSource ? `utm_source=${utmSource}` : ''
  const medium = utmMedium ? `utm_medium=${utmMedium}` : ''
  const campaign = utmCampaign ? `utm_campaign=${utmCampaign}` : ''

  let fullPath = `${path}${source}${medium}${campaign}`

  if (discountCode) {
    fullPath = fullPath.replace(/utm_/g, '&utm_')
  } else {
    fullPath = fullPath.replace(/utm_/g, '&utm_')
    if (fullPath.indexOf('&utm') > 0) {
      fullPath = fullPath.slice(0, fullPath.indexOf('&utm')) + '?' + fullPath.slice(fullPath.indexOf('utm'), fullPath.length)
    }
  }

  return (
    <>
      <meta http-equiv="refresh" content={`0; URL=${fullPath}`} />
    </>
  )
}

export default RedirectPageTemplate

export const pageQuery = graphql`
  query RedirectPageQuery($slug: String!, $locale: String) {
    page: contentfulPageRedirect(slug: { eq: $slug }, node_locale: { eq: $locale }) {
      utmCampaign
      utmMedium
      utmSource
      discountCode
      pageTo {
        ... on ContentfulProduct {
          slug
          __typename
        }
        ... on ContentfulBlogArticle {
          slug
          __typename
        }
        ... on ContentfulPage {
          slug
          __typename
        }
      }
      pageToUrl
    }
  }
`
